import { o_global } from "@gr-common/body/namespaces";
import breakpointInstance, { Breakpoint } from "./breakpoint";
import "./rwdTools.js";
import "./trackingHandler.js";

o_global.breakpoint ||= breakpointInstance;
/*                                                          */
(o_global as any).breakpointClass ||= Breakpoint;
o_global.Breakpoint ||= Breakpoint;

/*                                                  */
o_global.device ||= {} as never;
o_global.device.breakpoint ||= window.o_global.breakpoint;
