/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

import { o_global } from "@gr-common/body/namespaces";
import { assignNamespace } from "@gr-common/body/namespace-utils";
import { delegate, stop } from "../../exports/event.js";
import { push } from "../../exports/fragment";

const log = o_global.debug.logger("link-masking");

const EXTRA_PARAM = "extraParam";
const UB64E_MARKER = "ub64e";
const HREF_ATTR = "href";
const STYLE_ATTR = "style";
const TARGET_ATTR = "target";
const TOUCHMOVE_EVENT = "touchmove";
const TOUCHEND_EVENT = "touchend";
const DATA_ATTR = "data";

export function moveChildren(from: Element, children: NodeListOf<ChildNode>, to: Element): void {
  while (children.length > 0) {
    const child = from.removeChild(children[0]);
    to.appendChild(child);
  }
}

export function realClick(link: HTMLElement): void {
  link.click();
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function decodeUb64e(element: HTMLElement): string {
  try {
    const ub64eString = element.dataset[UB64E_MARKER];
    if (!ub64eString) {
      throw new Error(`data-${UB64E_MARKER} attribute is empty or not present`);
    }

    return window.atob(ub64eString);
  } catch (e) {
    log.error("Error decoding ub64e on", element, e);

    const attrs = element
      .getAttributeNames()
      .map((attr) => `${attr}: ${element.getAttribute(attr)}`);

    throw new Error(
      `Error decoding ub64e: ${(e as Error).message} on element: ${element.tagName} with attributes: ${attrs.join(", ")}`,
    );
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
function addTouchEvents(link: Element): void {
  function removeEventListener(element: Element): void {
    /*                                                               */
    element.removeEventListener(TOUCHMOVE_EVENT, onTouchMove);
    /*                                                               */
    element.removeEventListener(TOUCHEND_EVENT, onTouchEnd);
  }

  function onTouchMove(this: Element): void {
    removeEventListener(this);
  }

  function onTouchEnd(this: Element, e: Event): void {
    stop(e);
    removeEventListener(this);
    (this as HTMLElement).click();
  }

  link.addEventListener(TOUCHMOVE_EVENT, onTouchMove, { passive: true });
  link.addEventListener(TOUCHEND_EVENT, onTouchEnd);
}

export function replaceLink(this: Element): HTMLAnchorElement {
  const { attributes } = this;

  const classes = Array.from(this.classList);

  const style = this.getAttribute(STYLE_ATTR);
  const target = this.getAttribute(`${DATA_ATTR}-${TARGET_ATTR}`);
  const parent = this.parentNode;

  /*                                                                */
  /*                                  */
  const ub64d = decodeUb64e(this as never);
  const link = document.createElement("a");

  /*                            */
  classes
    .filter((c) => c !== UB64E_MARKER)
    .forEach((c) => {
      link.classList.add(c);
    });

  /*               */
  link.setAttribute(HREF_ATTR, ub64d);

  /*                                           */
  style && link.setAttribute(STYLE_ATTR, style);
  target && link.setAttribute(TARGET_ATTR, target);

  if (target && target !== "_self") {
    /*                                                                                        */
    link.setAttribute("rel", "noopener noreferrer");
  }

  /*                                           */
  Array.from(attributes)
    .filter(
      (attr) =>
        attr.name.indexOf(`${DATA_ATTR}-`) !== -1 && attr.name !== `${DATA_ATTR}-${UB64E_MARKER}`,
    )
    .forEach((attr) => {
      link.setAttribute(attr.name, attr.value);
    });

  /*                  */
  /*                                                                                        */
  /*                                                                         */
  moveChildren(this, this.childNodes, link);

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  addTouchEvents(link);

  /*                                              */
  parent!.replaceChild(link, this);

  return link;
}

export function getReal(link: HTMLLinkElement): HTMLLinkElement | HTMLAnchorElement {
  if (link.classList.contains(UB64E_MARKER)) {
    return replaceLink.call(link);
  }

  return link;
}

export function initLinkMasking(): void {
  document.querySelectorAll(`.${UB64E_MARKER}`).forEach((e) => {
    /*                                                   */
    /*                                                           */
    /*                                                                     */

    if (e.children.length === 0) {
      const span = document.createElement("span");
      span.setAttribute(STYLE_ATTR, "all: unset;");
      moveChildren(e, e.childNodes, span);
      e.appendChild(span);
    }
  });

  delegate(
    document,
    "touchstart",
    `.${UB64E_MARKER}`,
    function linkMaskingTouchStartHandler(this) {
      replaceLink.call(this);
    },
    { capture: true, passive: true },
  );

  delegate(document, "mouseover", `.${UB64E_MARKER}`, replaceLink, { passive: true });

  /*                          */
  /*                 */
  delegate(
    document,
    UB64E_MARKER as never,
    `.${UB64E_MARKER}, a`,
    function linkMaskingUb64eHandler(this) {
      if (this.classList.contains(UB64E_MARKER)) {
        realClick(replaceLink.call(this));
        return;
      }

      realClick(this as HTMLElement);
    },
    { passive: true },
  );
  /*               */
}

export function initExtraParam(): void {
  delegate(
    document,
    "mousedown",
    ".js_hasExtraParam",
    function linkMaskingAddExtraParam(this) {
      const url = this.getAttribute(HREF_ATTR)!;
      const extraParam = (this as HTMLElement).dataset[EXTRA_PARAM];

      if (extraParam) {
        this.setAttribute(HREF_ATTR, push(extraParam, url)!);
      }
    },
    { passive: true },
  );
}

/*                                            */
otto.dom.onReady.subscribe(() => {
  initLinkMasking();
  initExtraParam();
});

/*                                             */
assignNamespace(o_global, "links", {
  masking: { getReal, realClick, init: initLinkMasking },
  extraParam: { init: initExtraParam },
});
