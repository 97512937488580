import { type QBusEventMap, type QBusEventEnum, events } from "../../../exports/event-q-bus.js";
import type { OrientationTypes } from "./orientation.js";

/**
 *
 *
 */
export type TouchableDetectedEventData = {
  /*                                                                     */
  ontouchstart: boolean;
  /*                                                                                                         */
  documentTouch: boolean;
  /*                                                                 */
  maxTouchPoints: boolean;
};

export type HybridDetectedEventData = {
  /*                                                    */
  fromStorage?: boolean;
  /*                                               */
  fromDetector?: boolean;
};

/*                               */
/**
 *
 */
export type DeviceEvents = QBusEventMap<{
  /*                                                   */
  "assets.device.touchable.detected": [data: TouchableDetectedEventData];
  /*                                                */
  "assets.device.hybrid.detected": [data: HybridDetectedEventData];
  /*                                                    */
  "assets.device.orientation.changed": [activeOrientation: OrientationTypes];
}>;

export const EVENTS: QBusEventEnum<DeviceEvents> = {
  hybridDetected: "assets.device.hybrid.detected",
  touchableDetected: "assets.device.touchable.detected",
  orientationChanged: "assets.device.orientation.changed",
};

export const deviceEvents = events<DeviceEvents>();
