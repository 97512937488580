/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

window.exactag = window.exactag || window.pi || {}; /*                                 */

(function (win) {
  /*                        */

  try {
    if (typeof win.location.toString() !== "string") {
      win = window.self;
    }
  } catch (e) {
    win = window.self;
  }
  const logger = win.o_global?.debug.logger("exactag.pi.js");

  /*                   */

  let pi;
  let doc = win.document;
  const JSON = win.JSON || {};
  const Pi = function () {
    return undefined;
  };
  const Exactag = function () {
    return undefined;
  };

  const emailPattern =
    /[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(@|%40|%2540)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})/gi;

  /*                */

  const measureStart = new Date().getTime();
  const measureNamespace = "exactagRegex";
  let lastRegexMatch = "";

  Pi.prototype = {
    version: "3.0.20141107",
    dependencies: [
      {
        object: "JSON",
        property: "stringify",
        type: "function",
        scope: win,
      },
      {
        object: "JSON",
        property: "decycle",
        type: "function",
        scope: win,
      },
    ],
    cfg: {
      type: "Content",
      conversiontype: "",
      referrer: doc.referrer.replace(emailPattern, function () {
        if (
          lastRegexMatch !== doc.referrer &&
          !!window.AS &&
          !!window.AS.RUM &&
          !!window.AS.RUM.sendCustomRequest &&
          typeof window.AS.RUM.sendCustomRequest === "function"
        ) {
          window.AS.RUM.sendCustomRequest(measureNamespace, {
            duration: new Date().getTime() - measureStart,
            type: "referrer",
          });
        }

        lastRegexMatch = doc.referrer;

        return "";
      }),
      host: win.location.host,
      site: win.location.pathname,
      search: win.location.search.replace(emailPattern, function () {
        if (
          lastRegexMatch !== win.location.search &&
          !!window.AS &&
          !!window.AS.RUM &&
          !!window.AS.RUM.sendCustomRequest &&
          typeof window.AS.RUM.sendCustomRequest === "function"
        ) {
          window.AS.RUM.sendCustomRequest(measureNamespace, {
            duration: new Date().getTime() - measureStart,
            type: "search",
          });
        }

        lastRegexMatch = win.location.search;

        return "";
      }),
      campaign: "",
      screensize: "",
      pitype: "",
      trackingURL: "/*           */
    },
    cName: "exactag",
    contentNode: false,
    stopwatch: {
      start: new Date().getTime(),
    },
    rootDoc: null,
    uid: null,
    item: {},
    logStack: [],
    traceFrequency: 1e3,
    traceUntil: new Date(2013, 0, 26),
    traceEnabled: false,
    readyToTrack: false,
    nfif: true,
    enabled: true,
    style: "position:absolute; width:0px; height:0px; overflow: hidden; border: 0;",
    init() {
      this.getConfig();
      this.meantime("pi.init");
      this.createContainers();
    },
    createContainers() {
      /*                                       */
      if (doc.body === null) {
        win.setTimeout(this.createDelegate(this, this.createContainers), 10);
        return;
      }
      this.meantime("pi.createContainers");
      this.createContentNode();
      this.setReadyToTrack();
    },
    createDelegate(object, method) {
      return function (args) {
        method.apply(object, arguments, args);
      };
    },
    createContentNode() {
      /*                 */
      const root = doc.createElement("iframe");
      root.setAttribute("title", `${this.cName}_${this.uid}`);
      root.setAttribute("aria-hidden", "true");
      root.setAttribute("id", `${this.cName}_${this.uid}`);
      root.style.cssText = this.style;
      doc.getElementsByTagName("body")[0].appendChild(root);
      this.rootDoc = this.getDocument(root);
      try {
        this.rootDoc.open("text/html", "replace");
        /*                                                                       */
        /*                                                                  */
        /*                                                                                      */
        doc =
          this.ieVersion > 0 && this.ieVersion < 9 ? root.document || this.rootDoc : this.rootDoc;

        this.rootDoc.close();
      } catch (e) {
        this.report("accessing root iframe failed", "error");
        return;
      }
      this.contentNode = this.rootDoc.createElement("div");
      this.contentNode.id = `${this.cName}_pi_content`;
      this.rootDoc.body.appendChild(this.contentNode);

      this.log("this.rootDoc filled ", this.uid);
    },
    processItem(item) {
      if (typeof item !== "object") {
        this.report("no valid tracking object passed", "error");
        return;
      }

      if (!this.readyToTrack) {
        this.log("not ready to track yet ... retrying in 10ms");
        win.setTimeout(this.createDelegate(this, this.processItem), 10, item);
        return;
      }

      this.meantime("pi.processItem");

      let element;
      let src;

      /*                  */
      this.item = this.getTrackingConfig(item);
      this.log("trackingConfig", this.item);

      /*                                    */
      this.meantime("exctag.callHandler");

      src = `${this.item.trackingURL}/pi.aspx?campaign=${this.item.campaign}&pitype=${this.item.type}&convtype=${this.item.conversiontype}`;

      if (this.nfif === true) {
        try {
          this.post(`${src}&retmode=7`, {
            items: this.stringify(this.item),
          });
        } catch (f) {
          this.report("post to pi.aspx failed", "error");
        }
      } else {
        try {
          src += `&items=${encodeURIComponent(this.stringify(this.item))}`;

          element = this.rootDoc.createElement("script");
          element.setAttribute("src", src);
          element.setAttribute("type", "text/javascript");
          this.rootDoc.body.appendChild(element);
        } catch (g) {
          this.report("calling pi.aspx in iframe failed", "error");
        }
      }

      this.log("exactag reinit", window.exactag);

      /*                       */
      this.meantime("pi.handlerCalled");
    },
    getDocument(obj) {
      let prefix;
      let rdoc = null;
      try {
        rdoc = obj.contentDocument || obj.contentWindow.document || obj.document;
      } catch (e) {
        /*                                                              */
        prefix = "javascript";
        obj.setAttribute(
          "src",
          `${prefix}:(function(){document.open();document.domain="${doc.domain}";})()`,
        );
        rdoc = obj.contentDocument || obj.contentWindow.document || obj.document;
      }
      return rdoc;
    },
    appendHiddenField(name, value, parent) {
      let field;
      field = this.rootDoc.createElement("input");
      field.setAttribute("type", "hidden");
      field.setAttribute("name", name);
      field.setAttribute("value", value);
      parent.appendChild(field);
    },
    createContainer(id) {
      let s;
      const container = this.rootDoc.getElementById(id);
      if (container === null) {
        s = this.rootDoc.createElement("div");
        s.id = id;
        this.rootDoc.body.appendChild(s);
      }
      return s;
    },
    log(...args) {
      this.logStack.push(args);
      logger.debug(...args);
    },
    report(msg, type) {
      const err = {};
      let cfg;
      err.type = type || "error";
      err.uid = this.uid;
      err.component = "tracking";
      err.msg = msg;
      err.exactag = this.item;
      err.campaign = this.item.campaign || "";
      err.host = win.location.host;
      err.site = win.location.pathname;
      err.search = win.location.search.replace(emailPattern, "");
      err.ua = navigator.userAgent;
      err.log = this.logStack;
      err.version = this.version;

      cfg = this.bear(this.cfg);

      if (err.type === "error") {
        this.post(`${cfg.trackingURL}/jstrace.aspx`, {
          data: this.stringify(err),
        });
        this.log(`ERROR: ${msg}`);
      } else {
        this.log(msg, this.stringify(err));
        if (this.traceEnabled || this.debug) {
          this.post(`${cfg.trackingURL}/jstrace.aspx`, {
            data: this.stringify(err),
          });
        }
      }
    },
    setDebugMode() {
      const v = win.location.hash.indexOf(`${this.cName}_debug`) > -1 ? 1 : 0;
      this.debug = !!(v === 1);
      return this.debug;
    },
    meantime(title) {
      let time;
      const meantime = new Date().getTime();
      time = meantime - this.stopwatch.start;
      this.log(`${title}: ${time / 1000}s`);
    },
    getUid() {
      if (!this.uid) {
        this.uid = this.getRandomId(4);
      }
      return this.uid;
    },
    getRandomId(length) {
      let i;
      let rid = "";
      const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (i = 0; i < length; i++) {
        rid += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return rid;
    },
    getConfig() {
      /*                 */
      this.setDebugMode();
      this.getUid();

      /*           */
      this.logStack = [];
      this.item = {};
      this.stopwatch.start = new Date().getTime();

      try {
        if (window.exactag.nfif === true) {
          this.nfif = window.exactag.nfif;
          delete window.exactag.nfif;
        }
        if (
          this.traceFrequency > 0 &&
          Math.ceil(Math.random() * this.traceFrequency) === this.traceFrequency &&
          this.traceUntil.getTime() > new Date().getTime()
        ) {
          this.log("trace enabled");
          this.traceEnabled = true;
        }
      } catch (ignore) {}
    },
    extend(destination, source) {
      let property;
      for (property in source) {
        if (typeof source[property] !== "function" && source[property] !== undefined) {
          destination[property] = source[property];
        }
      }
      return destination;
    },
    setReadyToTrack() {
      this.readyToTrack = true;
    },
    getItem(data) {
      let i;
      const obj = {};
      for (i in data) {
        if (typeof data[i] !== "function") {
          obj[i] = data[i];
        }
      }
      return obj;
    },
    bear(obj) {
      return JSON.parse(JSON.stringify(JSON.decycle(obj)));
    },
    getTrackingConfig(item) {
      let i;
      const obj = this.bear(this.cfg);
      for (i in item) {
        if (item[i] !== undefined) {
          /*         */
          obj[i] = item[i];
        }
      }
      return obj;
    },
    stringify(mixed) {
      return JSON.stringify(JSON.decycle(mixed));
    },
    post(url, data) {
      let uid;
      let element;
      let form;
      let i;

      uid = `exactag_${this.getRandomId(5)}`;

      try {
        /*                                                           */
        element = this.rootDoc.createElement(`<iframe id='${uid}' name='${uid}'>`);
      } catch (e) {
        element = this.rootDoc.createElement("iframe");
        element.setAttribute("id", uid);
        element.setAttribute("name", uid);
      }

      this.rootDoc.body.appendChild(element);

      form = this.rootDoc.createElement("form");
      form.setAttribute("action", url);
      form.setAttribute("target", uid);
      form.setAttribute("method", "POST");

      for (i in data) {
        if (data[i] !== undefined) {
          this.appendHiddenField(i, encodeURIComponent(data[i]), form);
        }
      }

      this.rootDoc.body.appendChild(form);
      form.submit();
    },
    checkDependencies() {
      let i;
      const errors = [];
      let d;
      let hasError;
      for (i in this.dependencies) {
        if (typeof this.dependencies[i] === "object") {
          d = this.dependencies[i];
          hasError = false;
          try {
            if (typeof d.scope[d.object][d.property] !== "function") {
              hasError = true;
            }
          } catch (e) {
            hasError = true;
          }

          if (hasError) {
            errors.push(`${d.object}.${d.property} is not a ${d.type}`);
          }
        }
      }

      if (errors.length > 0) {
        this.disable(`found one or more missing dependencies\n - ${errors.join("\n - ")}`);
      } else {
        this.log("dependency detection OK", this.dependencies);
      }
    },
    disable(msg) {
      this.log(`ERROR: ${msg}`);
      /*                                                                          */
      /*                                                     */
      this.enabled = false;
    },
  };

  /*                     */

  Exactag.prototype = {
    track(obj) {
      const pi = new Pi();
      pi.checkDependencies();
      if (pi.enabled === true) {
        pi.init();
        pi.meantime("exactag.track");
        if (obj === undefined) {
          obj = pi.getItem(this);
          pi.log("item read from exactag object", obj);
        } else {
          pi.log("object injected into exactag.track", obj);
        }
        pi.processItem(obj);
        window.exactag = new Exactag();
      } else {
        logger.warn("dependency error: ", pi.logStack.pop());
      }
    },
  };

  /*            */

  /*                                                         */
  if (typeof window.exactag.campaign === "string") {
    /*             */
    pi = new Pi();
    pi.checkDependencies();
    if (pi.enabled === true) {
      pi.init();
      /*                                   */
      pi.log("found item in exactag", window.exactag);
      pi.processItem(pi.getItem(window.exactag));
    } else {
      logger.warn("dependency error: ", pi.logStack.pop());
    }
  }

  /*                                                                    */
  window.exactag = new Exactag();
})(window.top);
